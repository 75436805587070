import { Spinner } from 'govuk-react'
import React from 'react'
import { DrsUploadStatus } from '../../types'
import styles from './ProgressBarStep.module.scss'

export interface ProgressBarStepProps {
  /** Title of progress bar step. */
  title: string
  /**  Set status of upload progress.Default to not started  */
  status?: DrsUploadStatus
}

/** Progress bar step component with status as active or complete. Can be implemented into dynamic step progress bar. */
export const ProgressBarStep: React.FunctionComponent<ProgressBarStepProps> = ({
  title,
  status = DrsUploadStatus.NOT_STARTED
}) => {
  return (
    <div
      className={`${styles.stepItem} ${
        status === DrsUploadStatus.COMPLETE ? styles.completed : ''
      }`}
    >
      <div
        className={`${styles.stepCounter} ${
          status === DrsUploadStatus.IN_PROGRESS && styles.active
        }  ${status === DrsUploadStatus.ERROR && styles.uploadError}`}
      >
        {(() => {
          switch (status) {
            case DrsUploadStatus.COMPLETE:
              return <span className={styles.checkMark} />
            case DrsUploadStatus.ERROR:
              return <span className={styles.crossMark}>X</span>
            default:
              return (
                <Spinner
                  title={title}
                  width="24px"
                  height="24px"
                  color={
                    status === DrsUploadStatus.IN_PROGRESS
                      ? '#1D70B8'
                      : '#505A5F'
                  }
                />
              )
          }
        })()}
      </div>
      <div className={styles.stepName}>{title}</div>
    </div>
  )
}
