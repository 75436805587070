import React from 'react'
import styles from './ProgressBar.module.scss'

export interface ProgressBarProps {
  /** Step to be displayed within the ProgressBar */
  children: React.ReactNode
}

/** Intail Wrapper to create Step Progress Bar .Add mutiple step to create dyanmic progress bar step*/
export const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({
  children
}) => {
  return (
    <>
      <div className={`${styles.stepperWrapper}`}>{children}</div>
    </>
  )
}
